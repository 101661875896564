import { IGetUserDetailsParams } from "identity-admin-contracts/user-management";
import { axiosClient } from "@shared/api";

export const getUserDetailsV2 = ({
  appName,
  country,
  userId,
}: IGetUserDetailsParams) =>
  axiosClient.get(`/users/${userId}/info`, {
    params: {
      appName,
      country,
    },
  });
